import React from 'react'
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import { useHistory } from "react-router-dom";
import artinn from "../images/artinn.png";
import surl from '../images/surl.png'
import strdata from '../images/strdata.png'
import webscraping from '../images/webscraping.png'
import websitedata from '../images/website-data.png'

export default function Services(props) {
  const history = useHistory();

    return (
      <div>
      
        <div className="banner">
        <div className="container">
          <div className="innerbaner">
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
              <h1>
                Services
              </h1>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <div className="maincontent">
          <section className="servicesmain">
            <div className="container">
              <div className="servbox">
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={1.5}
                animateOnce={true}
              >
              <div className="seimg">
                  <img src={websitedata}></img>
                  </div>
                  <div className="secont">
                  <h2 className="headh2 h2mrgn">Websites/ Data</h2>
                  <p>Our proprietary systems can work with a plethora of data sources, including html content, json data, csv files, excel, SQL sources ( MySQL, PostgreSQL, Oracle, etc) and NoSQL (MongoDB, DynamoDB, etc). Most of our clients have a desire to capture data from websites and we specialize in scraping web pages, including dynamic websites driven by React, Vue and Angular as well as other JavaScript libraries and frameworks. Contrary to most of our competitors, we simply do not store data as strings in a database. Our clients may want to store data as integers, decimals, dates and other common data types. Our systems are flexible to support this demand.</p>
                  </div>
                  </ScrollAnimation>
              </div>
              <div className="servbox">
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={1.5}
                animateOnce={true}
              >
              <div className="seimg">
                  <img src={webscraping}></img>
                  </div>
                  <div className="secont">
                  <h2 className="headh2 h2mrgn">Web Scraping</h2>
                  <p>There are several prevailing models to scrape websites and we feverishly believe in using the right tool for the job. We have exercised various web scraping technologies from both Python and NodeJS and leverage the right tools to maximize efficiency and reduce costs. For the most complex sites, we are strongly proficient in both Selenium and Puppeteer. A web page will have all sorts of content, but most of our clients want to store data in a meaningful and organized way. We understand your needs and can implement most solutions.</p>
                  </div>
                  </ScrollAnimation>
              </div>
              <div className="servbox">
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={1.5}
                animateOnce={true}
              >
              <div className="seimg">
                  <img src={strdata}></img>
                  </div>
                  <div className="secont">
                  <h2 className="headh2 h2mrgn">Structured Data</h2>
                  <p>In order to analyze data and craft statistical probability distribution models or machine learning algorithms, sensible and structured data is requisite. We structure data based on the client’s request and needs. We always store data at a database level, but can easily provide data in csv format. Our data presentation is perfectly suitable for labeled and unlabeled samples in supervised and unsupervised machine learning models, respectively.</p>
                  </div>
                  </ScrollAnimation>
              </div>
              <div className="servbox">
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={1.5}
                animateOnce={true}
              >
              <div className="seimg">
                  <img src={surl}></img>
                  </div>
                  <div className="secont">
                  <h2 className="headh2 h2mrgn">Supervised, Unsupervised & Reinforcement Learning</h2>
                  <p>Based on the kind of data available and the research question at hand, we train our proprietory algorithms to suite the best statisical and machine learning models available, including supervised and unsupervised machine learning.</p>
                  </div>
                  </ScrollAnimation>
              </div>
              <div className="servbox servboxwth">
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={1.5}
                    animateOnce={true}
                  >
                  <div className="seimg">
                   <img src={artinn}></img>
                  </div>
                  <div className="secont">
                    <h2 className="headh2 h2mrgn">Artificial Neural Networks</h2>
                    <p>We leverage the power of Neural Networks to simulate the way the human brain analyzes and processes information. It is the foundation of artificial intelligence (AI) and solves problems that would prove impossible or difficult by human or statistical standards.</p>
                  </div>
                  </ScrollAnimation>
              </div>
            </div>
          </section>
        </div>
    </div>
    )
}