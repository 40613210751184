import logo from "../images/logo.png";
import services from "../images/services.png";
import number1 from "../images/number-1.png";
import number2 from "../images/number-2.png";
import number3 from "../images/number-3.png";
import number4 from "../images/number-4.png";
import number5 from "../images/number-5.png";
import footer from "../images/FOOTERLOGO.png";
import "../App.css";
import Slider from "../components/slider";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import { useHistory } from "react-router-dom";
function Landing() {
  const history = useHistory();
  return (
    <div>
      <div className="banner">
        <div className="container">
          <div className="banner-content">
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
              <h1>
                <span>Scrape</span>torium
              </h1>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
              <h2>By Infinite Limit Data Science LLC</h2>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="Services">
          <div className="container">
            <div className="serviceimg">
              <ScrollAnimation
                animateIn="animate__flipInX"
                duration={1.5}
                animateOnce={true}
              >
                <img src={services} />
              </ScrollAnimation>
            </div>
          </div>
        </div>
        <section className="stepss">
          <div className="container">
            <div className="first-sec">
              <div className="imgside">
                <div className="img imgleft">
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={1.5}
                    animateOnce={true}
                  >
                    <img src={number1} />
                  </ScrollAnimation>
                </div>
              </div>

              <div className="contside">
                <ScrollAnimation
                  animateIn="animate__fadeInUp"
                  duration={1.5}
                  animateOnce={true}
                >
                  <h2>Websites/ Data</h2>
                  <p>
                  Our proprietary systems can work with a plethora of data sources, including html content, json data, csv files, excel, SQL sources ( MySQL, PostgreSQL, Oracle, etc) and NoSQL (MongoDB, DynamoDB, etc). Most of our clients have a desire to capture data from websites and we specialize in scraping web pages, including dynamic websites driven by React, Vue and Angular as well as other JavaScript libraries and frameworks. Contrary to most of our competitors, we simply do not store data as strings in a database. Our clients may want to store data as integers, decimals, dates and other common data types. Our systems are flexible to support this demand.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
            <div className="secnd-sec">
              <div className="contside">
                <ScrollAnimation
                  animateIn="animate__fadeInUp"
                  duration={1.5}
                  animateOnce={true}
                >
                  <h2>Web Scraping</h2>
                  <p>
                  There are several prevailing models to scrape websites and we feverishly believe in using the right tool for the job. We have exercised various web scraping technologies from both Python and NodeJS and leverage the right tools to maximize efficiency and reduce costs. For the most complex sites, we are strongly proficient in both Selenium and Puppeteer. A web page will have all sorts of content, but most of our clients want to store data in a meaningful and organized way. We understand your needs and can implement most solutions.
                  </p>
                </ScrollAnimation>
              </div>
              <div className="imgside">
                <div className="img imgright">
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={1.5}
                    animateOnce={true}
                  >
                    <img src={number2} />
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            <div className="third-sec">
              <div className="imgside">
                <div className="img imgleft">
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={1.5}
                    animateOnce={true}
                  >
                    <img src={number3} />
                  </ScrollAnimation>
                </div>
              </div>
              <div className="contside">
                <ScrollAnimation
                  animateIn="animate__fadeInUp"
                  duration={1.5}
                  animateOnce={true}
                >
                  <h2>Structured Data</h2>
                  <p>
                  In order to analyze data and craft statistical probability distribution models or machine learning algorithms, sensible and structured data is requisite. We structure data based on the client’s request and needs. We always store data at a database level, but can easily provide data in csv format. Our data presentation is perfectly suitable for labeled and unlabeled samples in supervised and unsupervised machine learning models, respectively.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
            <div className="forth-sec">
              <div className="contside">
                <ScrollAnimation
                  animateIn="animate__fadeInUp"
                  duration={1.5}
                  animateOnce={true}
                >
                  <h2>Supervised, Unsupervised &amp; Reinforcement Learning</h2>
                  <p>
                  Based on the kind of data available and the research question at hand, we train our proprietory algorithms to suite the best statisical and machine learning models available, including supervised and unsupervised machine learning.
                  </p>
                </ScrollAnimation>
              </div>
              <div className="imgside">
                <div className="img imgright">
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={1.5}
                    animateOnce={true}
                  >
                    <img src={number4} />
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            <div className="fifth-sec">
              <div className="imgside">
                <div className="img imgleft">
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={1.5}
                    animateOnce={true}
                  >
                    <img src={number5} />
                  </ScrollAnimation>
                </div>
              </div>
              <div className="contside">
                <ScrollAnimation
                  animateIn="animate__fadeInUp"
                  duration={1.5}
                  animateOnce={true}
                >
                  <h2>Artificial Neural Networks</h2>
                  <p>
                  We leverage the power of Neural Networks to simulate the way the human brain analyzes and processes information. It is the foundation of artificial intelligence (AI) and solves problems that would prove impossible or difficult by human or statistical standards.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>
        <section className="client">
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            duration={1.5}
            animateOnce={true}
          >
            <div className="container">
              <h2 className="headh2">Our Clients</h2>
            </div>
            <Slider />
          </ScrollAnimation>
        </section>
      </div>
    </div>
  );
}

export default Landing;
