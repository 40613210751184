import React from 'react'
import { useHistory } from 'react-router-dom'
import footer from "../images/FOOTERLOGO.png";
import ScrollAnimation from "react-animate-on-scroll";


const Footer = (props) => {
    const history = useHistory()
    return (
      <>
      <div className="contactform">
          <div className="container">
            <div className="form">
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={1.5}
                animateOnce={true}
              >
                <h2>Contact us</h2>

                <form action="/action_page.php">
                  <div className="half">
                    <input
                      type="text"
                      id="fname"
                      name="firstname"
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="half">
                    <input
                      type="text"
                      id="lname"
                      name="lastname"
                      placeholder="Enter Email"
                    />
                  </div>
                  <div className="half">
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      placeholder="Enter Phone No."
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      required
                    />
                  </div>
                  <div>
                    <textarea
                      id="subject"
                      name="subject"
                      placeholder="Message Here..."
                      style={{ height: "200px" }}
                      defaultValue={""}
                    />
                  </div>
                  <div className="check">
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      defaultValue="Bike"
                    />
                    <label htmlFor="vehicle1">
                      I would like to be contacted with news and updates about
                      your events and services
                    </label>
                  </div>
                  <input type="submit" defaultValue="Send Message" />
                </form>
              </ScrollAnimation>
            </div>
          </div>
         </div> 
        <footer>
        <div className="container">
          <div className="footerlogo">
            <ScrollAnimation
              animateIn="animate__fadeInDown"
              duration={1.5}
              animateOnce={true}
            >
              <img src={footer} />
            </ScrollAnimation>
          </div>

          <div className="copyright">
            <p>Copyright 2021 All Right Reserved.</p>
          </div>
        </div>
      </footer>
      </>
    )
}

export default Footer