import React from 'react'
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import { useHistory } from "react-router-dom";
import careerlogo from '../images/carrer-logo.png'

export default function Services(props) {
  const history = useHistory();

    return (
      <div>
      
        <div className="banner">
        <div className="container">
          <div className="innerbaner">
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
              <h1>
              <span>Our</span> Careers
              </h1>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <div className="maincontent">
          <section className="carersmain">
          <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
          <h2 className="headh2 centerh2">Our Careers</h2>
          </ScrollAnimation>
              <div className="container">
              <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
                <div className="carebx">
                    <div className="cimgbxx">
                     <img src={careerlogo}></img>
                    </div>
                    <div className="carcontent">
                     <h2>
                         Machine Learning Data Scientist
                        <span></span>
                     </h2>
                     <a href="JavaScript:void(0)" onClick={()=>{history.push("contact")}}>Contact Us</a>
                    </div>
                   
                </div>
                </ScrollAnimation>
              </div>
          </section>
          
        </div>

      
    </div>
    )
}