import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import img1 from '../images/logo1.png'
import img2 from '../images/logo2.png'
import img3 from '../images/logo3.png'
import img4 from '../images/logo4.png'
import img5 from '../images/logo5.png'
import img6 from '../images/logo6.png'
import img7 from '../images/logo7.png'
import img8 from '../images/logo8.png'
import img9 from '../images/logo9.png'
const handleDragStart = (e) => e.preventDefault();

const items = [
  <img
    src={img1}
    
    onDragStart={handleDragStart}
  />,
  <img
    src={img2}
    
    onDragStart={handleDragStart}
  />,
  <img
    src={img3}
    
    onDragStart={handleDragStart}
  />,
  <img
    src={img4}
    
    onDragStart={handleDragStart}
  />,
  <img
    src={img5}
    
    onDragStart={handleDragStart}
  />,
  <img
    src={img6}
    
    onDragStart={handleDragStart}
  />,
  <img
    src={img7}
    
    onDragStart={handleDragStart}
  />,
  <img
    src={img8}
    
    onDragStart={handleDragStart}
  />,
  <img
    src={img9}
    
    onDragStart={handleDragStart}
  />,
];

const Gallery = () => {
  return (
    <AliceCarousel
      mouseTracking={false}
      infinite
      autoPlay
      autoPlayInterval={1200}
      autoHeight
      responsive={ {
        0: {
            items: 2,
        },
        768: {
            items: 4,
        },
        1100: {
          items: 6
      }
      }}
      items={items}
    />
  );
}; 

export default Gallery;
