import React from 'react'
import {useHistory} from 'react-router-dom'
import logo from "../images/logo.png";


const Header = (props) =>{
    const history = useHistory()
    return(
        <div className="header">
        <div className="navigation">
          <div className="container">
            <div className="sitelogo">
              <div className="logimg">
                <a href="JavaScript:void(0)" onClick={()=>{history.push("/")}}>
                  <img src={logo} />
                </a>
              </div>
            </div>
            
            <div className="menu-icon"></div>
            <div className="nav-menu">
                <ul>
                  <li>
                    <a href="JavaScript:void(0)" onClick={()=>{history.push("/")}}>Home</a>
                  </li>
                  <li>
                    <a href="JavaScript:void(0)" onClick={()=>{history.push("about-us")}}>
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="JavaScript:void(0)" onClick={()=>{history.push("services")}}>Services</a>
                  </li>
                  <li>
                    <a href="JavaScript:void(0)" onClick={()=>{history.push("careers")}}>Careers</a>
                  </li>
                  <li>
                    <a href="JavaScript:void(0)" onClick={()=>{history.push("contact")}}>Contact Us</a>
                  </li>
                </ul>
              </div>
          </div>
        </div>
      </div>
    )
}

export default Header