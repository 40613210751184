
import "./App.css";
import Landing from './screens/Landing'
import About from './screens/About'
import Services from './screens/services'
import Careers from './screens/careers'
import Contact from './screens/contact-us'
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Header from './components/header'
import Footer from './components/footer'
function App() {
  return (
    <div className="wrapper">
      <Router>
        <Header />
        <Switch>
          <Route exact path="/about-us">
            <About />
          </Route>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route exact path="/services">
            <Services />
          </Route>
          <Route exact path="/careers">
            <Careers />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
