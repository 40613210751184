import React from 'react'
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import { useHistory } from "react-router-dom";
import phone from '../images/phone-ringing.png'
import mail from '../images/mail.png'
import pin from '../images/pin.png'
import mapp from '../images/mapp.png'

export default function Services(props) {
  const history = useHistory();

    return (
      <div>
      
        <div className="banner">
        <div className="container">
          <div className="innerbaner">
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
              <h1>
                 Contact <span>Us</span>
              </h1>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <div className="maincontent">
      <section className="contmain">
          <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
          <h2 className="headh2 centerh2">Get in Touch</h2>
          </ScrollAnimation>
              <div className="container">
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
                <div className="col-6">
                <a  className="mapimg" target="_blank" href="https://goo.gl/maps/5Wo4Uiuq2rgdffbB6">
                <img src={mapp}></img>
                </a>
                </div>
            </ScrollAnimation>
           
                <div className="col-6">
                   <ul className="continfo">
                        <ScrollAnimation
                        animateIn="animate__fadeInUp"
                        duration={1.5}
                        animateOnce={true}
                        >
                       <li>
                           <div className="icnbx">
                                <img src={phone}></img>
                           </div>
                           <div className="contctcont">
                                <h3>Phone</h3>
                                <a href="tel:+1 954 479 8649">+1 954 479 8649</a>
                           </div>
                       </li>
                       </ScrollAnimation>
                       <ScrollAnimation
                        animateIn="animate__fadeInUp"
                        duration={1.5}
                        animateOnce={true}
                        >
                       <li>
                           <div className="icnbx">
                                <img src={mail}></img>
                           </div>
                           <div className="contctcont">
                                <h3>Email</h3>
                                <a href="mailto:info@scrapetorium.com">info@scrapetorium.com</a>
                           </div>
                       </li>
                       </ScrollAnimation>
                       <ScrollAnimation
                        animateIn="animate__fadeInUp"
                        duration={1.5}
                        animateOnce={true}
                        >
                       <li>
                           <div className="icnbx">
                                <img src={pin}></img>
                           </div>
                           <div className="contctcont">
                                <h3>Location</h3>
                                <a target="_blank" href="https://goo.gl/maps/5Wo4Uiuq2rgdffbB6">6451 N Federal Highway, Fort Lauderdale, FL</a>
                           </div>
                       </li>
                       </ScrollAnimation>
                   </ul>
                   
                </div>
            
              </div>
          </section>
        </div>

    </div>
    )
}