import React from 'react'
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import { useHistory } from "react-router-dom";
import danielimg from '../images/danielimg.png'
import lazuardy from '../images/lazuardy.png'
import Naveen from '../images/Naveen.png'
import anastasia from '../images/anastasia.png'
import GloriaRivera from '../images/GloriaRivera.png'

export default function About(props) {
  const history = useHistory();

    return (
      <div>
        
        <div className="banner">
        <div className="container">
          <div className="innerbaner">
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
              <h1>
                About <span>Us</span>
              </h1>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <div className="maincontent">
     
        <section className="abouttop">
          <div className="container">
          <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
              <h2  className="headh2 h2mrgn">About Us </h2>
              <p>Our proprietary systems can work with a plethora of data sources, including html content, json data, csv files, excel, SQL sources ( MySQL, PostgreSQL, Oracle, etc) and NoSQL (MongoDB, DynamoDB, etc). Most of our clients have a desire to capture data from websites and we specialize in scraping web pages, including dynamic websites driven by React, Vue and Angular as well as other JavaScript libraries and frameworks. Contrary to most of our competitors, we simply do not store data as strings in a database. Our clients may want to store data as integers, decimals, dates and other common data types. Our systems are flexible to support this demand.</p>
              </ScrollAnimation>
          </div>
        </section>
        
        <section className="teammember">
          <div className="container">
          <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
            <div className="teambio">
              <div className="imgsec">
                <img src={danielimg}></img>
              </div>  
              <div className="imgcontent">
                <h3>Daniel Viglione</h3>
                <span>Founder and CEO</span>
                <p>Daniel Viglione graduated top honors (Sum Cum Laude) from Temple University. He has more than 15 years of real-world software and technology experience, leveraging software and DevOps tools to accelerate growth in various industries. Employed at large corporations (Novartis - 150,000+ employees) to mid-sized level companies (Ambrose Health - 150+ employees), He has built HIPAA-compliant, secure and automated infrastructure and software that enabled companies to scale, grow and meet deadlines on time and on budget. Using his background in Kubernetes, AWS and Terraform, among other tools, he has engineered a scalable scraping solution that enabled him to flip more than 5 properties over the past 10 years in Broward and Palm Beach County, respectively, while concurrently building nationwide scraping engines for Law Firms and Home Investment companies.</p>
              </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
          <div className="teambio">
              <div className="imgsec">
                <img src={GloriaRivera}></img>
              </div>  
              <div className="imgcontent">
                <h3>Gloria Plaza</h3>
                <span>Accountant</span>
                <p>Gloria Plaza is our senior Tax Accountant. She has over 30 years of accounting experience for all types of companies, including small, mid-sized and large corporations, such as the ubiquitious HR Block. Originally from Pennsylvania, she moved to Florida a few years ago in pursuit of greater opportunities in the accounting industry.</p>
              </div>
          </div>
          </ScrollAnimation>
          </div>
        </section>
      </div>
    
      
    </div>
    )
}